import Vue from 'vue/dist/vue.esm';
import VModal from 'vue-js-modal'

Vue.use(VModal)

const date = new Date();

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#app',

    data: {
      disabledDatesEndpoint: "/api/v3/disabled_dates",
      availabilityEndpoint: "/api/v3/availabilities",
      date: new Date(),
      disabledDates: [],
      women_qty: 1,
      men_qty: 1,
      slots: [],
      note: '',
      showModal: false,
      selected_date: null,
      calendarLoading: false,
    },

    mounted() {
      this.women_qty = document.getElementById('women').value
      this.men_qty = document.getElementById('men').value
      this.getDisabledDates();
    },
    computed: {
      totalPeople() {
        return this.women_qty + this.men_qty
      }
    },

    created() {
    },

    methods: {
      init() {
      },

      getDisabledDates: function () {
        this.calendarLoading = true
        var vm = this;
        //vm.calendar_text = "Haetaan päiviä..."
        var month = vm.date.getMonth()+1
        fetch(this.disabledDatesEndpoint+'?month=' + month + '&women=' + vm.women_qty + "&men=" + vm.men_qty)
        .then(function(response) {
          if (response.status !== 200) {
            return;
          }
          response.json().then(function(data) {
            data.forEach((s) => {
              var d = s.split('-')
              vm.disabledDates.push(new Date(d[0], d[1]-1, d[2]))
              //console.log(data)
            });

            vm.initCalendar()
            vm.calendarLoading = false
          });
        });
      },

      getTimes: function (date) {
        var vm = this;
        vm.selected_date = date
        if (date != null) {
          var day = date.getDate()
          var month = date.getMonth()+1
          var year = date.getFullYear()
          document.getElementById('date').value = day + '.' + month + '.' + year
          fetch(this.availabilityEndpoint+ '?month=' + month + '&day=' + day + '&year=' + year + '&men=' + vm.men_qty + '&women=' + vm.women_qty, {
            method: 'POST',
            body: vm.dayData
          })
          .then(function(response) {
            if (response.status !== 200) {
              return;
            }
            response.json().then(function(data) {
              vm.slots = data.hours
              vm.note = data.note
              document.getElementById('price').value = data.total
              console.log(data)
            })
          })
        }
      },

      initCalendar: function () {
        var vm = this;

        var customDays_fi = ['Su', 'Ma', 'Ti', 'Ke', 'To', 'Pe', 'La']
        var customDays_en = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        var customMonths_fi =['Tammikuu', 'Helmikuu', 'Maaliskuu', 'Huhtikuu', 'Toukokuu', 'Kesäkuu', 'Heinäkuu', 'Elokuu', 'Syyskuu', 'Lokakuu', 'Marraskuu', 'Joulukuu']
        var customMonths_en = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

        var lang = document.getElementById('lang').value

        if (lang == "fi") {
          var customDays = customDays_fi
          var customMonths = customMonths_fi
        }
        if (lang == "en") {
          var customDays = customDays_en
          var customMonths = customMonths_en
        }

        const picker = datepicker('.dp', {
          alwaysShow: true,
          disabledDates: vm.disabledDates,
          startDay: 1,
          startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
          minDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
          customDays: customDays,
          customMonths: customMonths,
          onSelect: (instance, date) => {
            vm.getTimes(date)
          }
        })
      },
      submit : function(){
        this.$refs.form.submit()
      },

      showTerms () {
        if (lang == "fi") {
          var title = "Toimitusehdot"
          var close_button = "Sulje"
        }
        if (lang == "en") {
          var title = "Terms and conditions"
          var close_button = "Close"
        }

        this.$modal.show('terms', {
          title: title,
          height: 'auto',
          scrollable: true,
          buttons: [
            {
              title: close_button
            }
          ]
        });
      },

      showPrivacy () {
        var lang = document.getElementById('lang').value

        if (lang == "fi") {
          var title = "Yksityisyydensuoja"
          var close_button = "Sulje"
        }
        if (lang == "en") {
          var title = "Privacy"
          var close_button = "Close"
        }

        this.$modal.show('privacy', {
          title: title,
          height: 'auto',
          scrollable: true,
          buttons: [
            {
              title: close_button
            }
          ]
        });
      },

    },
  });
});
